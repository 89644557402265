/* * {color: red} */

:root {
  --customColor: red;
  --bs-link-color: #02b2fe;
  --bs-link-hover-color: #02b2fe;
}

.modal .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.411);
}

.modal .MuiBox-root:focus-visible {
  outline: 0;
}

.loader .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.637);
}

.loader .MuiDialog-container {
  background-color: rgba(0, 0, 0, 0.637);
}

.GOOGLE {
  /* background-color: #F4F8FA !important; */
  border-radius: 25px !important;
  box-shadow: none !important;
  border: 1px solid rgba(145, 158, 171, 0.32) !important;
  justify-content: center;
  width: 100% !important;
}

.Toastify__toast {
  text-transform: capitalize;
}

@media screen and (max-height: 700px) {
  .responsive-footer {
    display: block;
  }
}

.card-container {
  max-width: 95%;
  margin: 0 auto;
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.not-found {
  padding: 16px 0px;
  text-align: center;
}

.table-container {
  background-color: transparent;
}

@media (max-width: 1842px) {
  .responsive-btn {
    font-size: 8px;
  }
}

.custom-notes .MuiOutlinedInput-notchedOutline,
.custom-notes .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #02b2fe;
}

.notes-container {
  color: #02b2fe;
  background-color: #d1e9fc;
  padding: 14px 16px;
  border-radius: 16px;
}

.disabled-dropzone {
  opacity: 0.6; /* Reduce opacity to visually indicate disabled state */
  pointer-events: none; /* Disable pointer events to block user interaction */
  /* Add any other styles to visually indicate the disabled state */
}

.left {
  justify-content: flex-start;
}

.right {
  justify-content: flex-end;
}

.chat-container {
  position: relative;
  height: 50vh;
}
.seller-chat-container {
  position: relative;
  height: 61%;
}

canvas.react-pdf__Page__canvas {
  margin-bottom: 50px;
}

.myCustomEditor {
  height: 250px;
}

/* loading.css */
.loading-dots {
  font-size: 24px;
  display: inline-block;
}

.loading-dots span {
  animation: loading-dots 1s infinite;
  margin: 0 2px;
}

.loading-dots span:nth-child(1) {
  animation-delay: 0s;
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loading-dots {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

.react-simple-image-viewer__slide img {
  max-width: 80%;
  max-height: 80%;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease-in-out;
  background-color: #fff;
}

.react-simple-image-viewer__slide img:hover {
  transform: scale(1.05);
}

.my-custom-datepicker .react-datepicker__header {
  background-color: #fff;
}

.my-custom-datepicker .react-datepicker__navigation--previous {
  border-color: transparent; /* remove default arrow style */
  background: transparent; /* some custom background color */
  color: #fff;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
}

.my-custom-datepicker .react-datepicker__navigation--next {
  border-color: transparent;
  background: transparent;
  color: #fff;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
}

.my-custom-datepicker .react-datepicker__navigation--previous::before {
  content: "<";
  color: #fff;
  position: relative;
  left: 10px;
  top: 3px;
  font-weight: bold;
}
.my-custom-datepicker .react-datepicker__navigation--next::before {
  content: ">";
  color: #fff;
  position: relative;
  left: 10px;
  top: 3px;
  font-weight: bold;
}

.my-custom-datepicker .react-datepicker__month-text {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 60px;
  height: 50px;
  margin: 4px;
  border-radius: 4px;
  cursor: pointer;

  background-color: #f5f5f5;
  transition: background-color 0.2s, box-shadow 0.2s;
}

.my-custom-datepicker .react-datepicker__month-text:hover {
  background-color: #eaeaea;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}

.my-custom-datepicker .react-datepicker__month-text--selected,
.my-custom-datepicker .react-datepicker__month-text--in-range,
.my-custom-datepicker .react-datepicker__month-text--keyboard-selected {
  background-color: #02b2fe;
  color: #fff;
  font-weight: bold;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}
a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

img {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
}
